import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import styles from "../styles/costumes.module.scss"

import Layout from "../components/Layout"
import PageCover from "../components/PageCover"

const Costumes = () => {
  const data = useStaticQuery(graphql`
    query {
      getCoverImage: file(relativePath: { eq: "costumes/cover.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      getCegadaImage: file(relativePath: { eq: "costumes/cegada.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      getMalhadaImage: file(relativePath: { eq: "costumes/malhada.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      getCarnavalImage: file(relativePath: { eq: "costumes/carnaval.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      getFeiraFumeiroImage: file(
        relativePath: { eq: "costumes/feira-fumeiro.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <PageCover
        image={
          <Img
            fluid={data.getCoverImage.childImageSharp.fluid}
            alt="Tourenses durante a segada"
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "cover" }}
          />
        }
        title="Costumes"
      />
      <div className={styles.wrapper}>
        <section>
          <div className={styles.sectionInnerWrapper}>
            <div className={styles.description}>
              <div className={styles.descriptionInnerWrapper}>
                <h1>Segada</h1>
                <p>
                  A tradicional segada, prévia à malhada, é a ceifa, o pão que
                  se corta nos alvores da manhã, seitoira na mão e centeio na
                  outra. Acontece no final da Primavera, quando o trigo já
                  crescido, deixou o verde e se tornou no amarelo que se haverá
                  de levar ao moinho e ao forno. A segada dos cereais faz-se no
                  mês à roda do S. João, no Junho do quase estio, pica e corte.
                </p>
              </div>
            </div>
            <div className={styles.image}>
              <Img
                fluid={data.getCegadaImage.childImageSharp.fluid}
                alt="Tourenses na segada"
              />
            </div>
          </div>
        </section>
        <section>
          <div className={styles.sectionInnerWrapper}>
            <div className={styles.description}>
              <div className={styles.descriptionInnerWrapper}>
                <h1>Malhada</h1>
                <p>
                  O dia da malhada é o dia de mais trabalho e maior festa.
                  Depois de feita a colheita do cereal, a segada, chega a tarefa
                  de separar o grão da palha. Centeio e trigo, semeados em
                  Setembro, após a descida dos animais para as cortes. A malhada
                  é a debulha, entre Julho e Agosto, com caldo de leite com
                  abobora no fim da jorna.
                </p>
              </div>
            </div>
            <div className={styles.image}>
              <Img
                fluid={data.getMalhadaImage.childImageSharp.fluid}
                alt="Tourenses na malhada"
              />
            </div>
          </div>
        </section>
        <section>
          <div className={styles.sectionInnerWrapper}>
            <div className={styles.description}>
              <div className={styles.descriptionInnerWrapper}>
                <h1>Carnaval</h1>
                <p>
                  Na Terça-feira Gorda, último dia do Entrudo, a comunidade
                  reúne-se para o Enterro do Rico Irmão, filho da aldeia que
                  deixa testamento, julgado em tribunal público, acusado e
                  chacoteado, à espera da beira do rio, onde se cumpre destino
                  trágico de farsa. Cumprido o julgamento, a Farsa prossegue com
                  escarnio e procissão até ao Côvo.
                </p>
              </div>
            </div>
            <div className={styles.image}>
              <Img
                fluid={data.getCarnavalImage.childImageSharp.fluid}
                alt="Personagem do carnaval"
              />
            </div>
          </div>
        </section>
        <section>
          <div className={styles.sectionInnerWrapper}>
            <div className={styles.description}>
              <div className={styles.descriptionInnerWrapper}>
                <h1>Feira do Fumeiro</h1>
                <p>
                  A Feira do Fumeiro é o grande certame da freguesia, celebrando
                  o bácoro, que engorda no Inverno e quase sempre a seguir ao
                  Natal e antes do Carnaval, a matança e desmancha. O reco por
                  lá fica ao dependurado e as tripas, mais a bexiga, vão ao rio
                  a lavar. Separadas as tripas, diferentes para chouriços ou
                  salpicões, enchem-se as que reclamam acrescento: sal, salsa,
                  cebola e lencinhos.
                </p>
              </div>
            </div>
            <div className={styles.image}>
              <Img
                fluid={data.getFeiraFumeiroImage.childImageSharp.fluid}
                alt="Feira do Fumeiro"
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Costumes
